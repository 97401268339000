<template>
  <section
    class="flex flex-col flex-1 px-5 pt-3 overflow-y-auto overflow-x-hidden bg-gray-100 dark:bg-gray-850 sidebar"
  >
    <slot />
  </section>
</template>

<style scoped>
.sidebar {
  width: 18.75rem;
}
</style>
